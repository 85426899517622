<template>
  <v-container>
    <h1 class="font-semibold text-xl">Referral System</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-card color="mt-5" flat>
      <v-card-text>
        <h4 class="mb-3 text-sm font-semibold">
          MAKE MONEY BY JUST INTRODUCING PEOPLE TO US
        </h4>

        <p class="text-sm leading-normal">
          To show our appreciation to all our customers effort to refer people
          to us, because we understand that more percentage of our customers get
          to know us by being referred by other satisfied customers. We have
          therefore implemented a referral program in terms of reward to show
          our appreciation to our customers who refer others to us.
        </p>

        <h4 class="mb-3 text-sm font-semibold">How does it work ?</h4>
        <p class="text-sm leading-normal">
          A unique referral link has been created for every registered customer
          whether verified or UN-verified, you can share your link via
          email,Whatsapp,Instagram, Facebook, blog etc to get people to sign up
          under you.
        </p>

        <h4 class="mb-3 text-sm font-semibold">How much will I earn?</h4>
        <p class="text-sm leading-normal">
          Anyone who click on this link and register on our website becomes your
          referral and you will receive {{ data.referPercentage }}% of their
          total transactions both on their BUY and SELL order for a life time.
        </p>

        <p class="text-sm leading-normal">
          This simply means any time they buy or sell on our website, you will
          get commission For example, if the person you referred did a
          transaction of <b>{{ calculateAmountBasedOnUser(1000000) }}</b> on our
          website, you will receive {{ data.referPercentage }}% of
          <b>{{ calculateAmountBasedOnUser(1000000) }}</b> which is
          <b>{{ calculateBonusBasedOnUser(1000000) }}</b>
        </p>

        <p class="text-sm leading-normal">
          If the person did a transaction of
          <b>{{ calculateAmountBasedOnUser(2000000) }}</b> you will receive
          <b>{{ calculateBonusBasedOnUser(2000000) }}</b>
          If the person did a transaction of
          <b>{{ calculateAmountBasedOnUser(20000000) }}</b> you will receive
          <b>{{ calculateBonusBasedOnUser(20000000) }}</b>
          And so on.
        </p>

        <p class="text-sm leading-normal">
          Commission can be withdrawn into your SnappyExchange wallet which you
          can withdraw directly into your bank account or you can use the same
          to purchase any of our services.
        </p>

        <p class="text-sm leading-normal font-semibold">
          NOTE: The minimum withdrawal is {{ calculateAmountBasedOnUser(2500) }}.
        </p>

        <h4 class="mb-3 text-sm font-semibold">Rules</h4>
        <p class="text-sm leading-normal">
          Referring oneself is a fraud! You cannot refer yourself in anyway
          (either in disguise or open). We have put security mechanisms to check
          this type of fraud. Any account detected to have perpetuated this type
          of fraud will have all commission earned frozen at the point of
          withdrawal and account closed by the admin.
        </p>

        <h4 class="mb-3 text-sm font-semibold">How do I start ?</h4>
        <p>
          It is very simple to start. Just copy your referrer link below and
          start telling people about snappy exchange.
        </p>
        <v-divider class="mb-4" />
        <v-row>
          <v-container class="px-10">
            <h4>Referral</h4>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  readonly
                  dense
                  name="email"
                  type="email"
                  label="Referral Code"
                  :value="user.username"
                >
                  <v-btn
                    icon
                    @click="copy(user.username)"
                    slot="append"
                    color="accent"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-text-field>
                <!-- <p>SHARE</p>
            <v-btn-toggle>
              <v-hover v-slot="{ hover }">
                <template>
                  <v-btn
                    :to="`https://www.facebook.com/sharer/sharer.php?u=${shareContent}`"
                    :color="hover ? 'primary' : ''"
                  >
                    <v-icon :color="hover ? 'white' : ''" left
                      >mdi-facebook</v-icon
                    >
                    Facebook
                  </v-btn>
                </template>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :to="`https://twitter.com/intent/tweet?text=${shareContent}`"
                  :color="hover ? 'primary' : ''"
                >
                  <v-icon :color="hover ? 'white' : ''" left
                    >mdi-twitter</v-icon
                  >
                  Twitter
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :to="`https://api.whatsapp.com/send?phone=2348160730668&text=${shareContent}`"
                  :color="hover ? 'primary' : ''"
                >
                  <v-icon :color="hover ? 'white' : ''" left
                    >mdi-whatsapp</v-icon
                  >
                  Whatsapp
                </v-btn>
              </v-hover>
            </v-btn-toggle> -->
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  id="reflink"
                  readonly
                  dense
                  name="link"
                  type="url"
                  label="Referral Link"
                  :value="`app.snappyexchange.com/register?ref=${user.username}`"
                >
                  <v-btn
                    data-clipboard-target="#reflink"
                    icon
                    @click="
                      copy(
                        `app.snappyexchange.com/register?ref=${user.username}`
                      )
                    "
                    slot="append"
                    color="accent"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <p>
          We have provided you with some images to help promote your link, click
          on any to download
        </p>
        <v-row>
          <v-col cols="12" md="4">
            <v-img
              class="cursor-pointer"
              @click="forceFileDownload(1)"
              width="100%"
              height="100%"
              :src="referral1"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              class="cursor-pointer"
              @click="forceFileDownload(2)"
              :src="referral2"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              class="cursor-pointer"
              @click="forceFileDownload(3)"
              :src="referral3"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-5">
      <v-card-title>Referral Logs</v-card-title>
      <v-data-table :headers="headers" :items="user.referrals">
        <template v-slot:[`item.status`]="{ item }">
          <span
            :class="`text-capitalize rounded py-2 px-3 white--text ${
              item.status ? 'success' : 'error'
            }`"
            >{{ item.status ? 'active' : 'suspended' }}</span
          >
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <p>You haven't referred anybody yet, refer and start earning!</p>
          </div>
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <span>{{ item.fname }} {{ item.lname }} </span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{
            new Date(item.created_at || item.createdAt).toDateString()
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../api/systemData'
import referral1 from '../../assets/img/referral_1.jpeg'
import referral2 from '../../assets/img/referral_2.jpeg'
import referral3 from '../../assets/img/referral_3.jpeg'
import { numberWithCommas } from '../../utils/helpers'

export default {
  name: 'ReferralSystem',
  data() {
    return {
      numberWithCommas,
      data: {},
      referral1,
      referral2,
      referral3,
      headers: [
        {
          text: 'Full Name',
          value: 'fullName',
          sortable: false
        },
        {
          text: 'Username',
          value: 'username'
        },
        {
          text: 'Date Joined',
          value: 'created'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    })
  },
  created() {
    this.getData()
  },
  methods: {
    getProfile() {},
    forceFileDownload(response) {
      const url =
        response === 1
          ? this.referral1
          : response === 2
          ? this.referral2
          : this.referral3

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `banner${response}.jpeg`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    async getData() {
      const res = await dataApi.data().siteSettings('user')
      this.data = res.data.data
    },
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    },
    calculateAmountBasedOnUser(amountInNaira) {
      const amount =
        amountInNaira *
        this.countrySetting[this.userCountry.toLowerCase()].nairaRate

      return `${this.userCurrency} ${numberWithCommas(amount)}`
    },
    calculateBonusBasedOnUser(amountInNaira) {
      const amount =
        amountInNaira *
        this.countrySetting[this.userCountry.toLowerCase()].nairaRate

      return `${this.userCurrency} ${numberWithCommas(
        (this.data.referPercentage / 100) * amount
      )}`
    }
  }
}
</script>

<style></style>
